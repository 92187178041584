import React, { forwardRef } from "react";
import ExperienceBox from "../components/ExperienceBox";
import styles from "./Experiences.module.css";
import pdf from "../static/Joseph_Lee_Resume.pdf";

const Experiences = forwardRef((props, ref) => {
  return (
    <div id="experience" ref={ref} className={styles.experiencesContainer}>
      <div className={styles.headerShowHide}>Experiences</div>
      <ExperienceBox
        date="Dec 2024 — Present"
        location="Mountain View, CA"
        position="Software Engineer"
        company="Google"
        description="Ads Shopping Infra"
        skills={["C++"]}
      />
      <ExperienceBox
        date="Aug 2024 — Nov 2024"
        location="New York, NY"
        position="Software Engineer"
        company="Capital One"
        description="Developed the Java Spring Boot backend MVP for the Capital One Zelle platform's new scheduled payment feature. Led the development of the Angular frontend MVP for an internal application that enables customer servicing agents to manage and view scheduled Zelle payments.  (Zelle Team)"
        skills={["Java", "Springboot", "Angular", "AWS"]}
      />
      <ExperienceBox
        date="Aug 2023 — Aug 2024"
        location="New York, NY"
        position="Software Engineer"
        company="Capital One"
        description="Successfully migrated highly critical production components from AWS EC2 to Fargate, ensuring performance integrity through rigorous regression, endurance, and unit testing. Enhanced developer experience by contributing to an internal CLI tool that streamlined deployments by automating retrieval of active stack details, CNAMEs, and code versions, eliminating the need for manual steps. (Financial Core Tech Team)"
        skills={["Scala", "Zio", "Go", "AWS"]}
      />
      <ExperienceBox
        date="Jun 2022 — Aug 2022"
        location="San Francisco, CA"
        position="Software Engineer Intern"
        company="Capital One"
        description="Developed the frontend for a full-stack application that allows teams to easily configure and approve their own digital assets such as API, datasets, and features that previously had to be configured through a manual, git-based approval process (Exchange Team)"
        skills={[
          "React.js",
          "Redux",
          "TypeScript",
          "REST API",
          "Jest",
          "Cypress",
        ]}
      />
      <ExperienceBox
        date="Jun 2021 — Aug 2021"
        location="Remote"
        position="Software Engineer Intern"
        company="Nokia"
        description="Developed backend data pipeline projects for an intelligence platform that analyzes real-time network traffic data for many of the world’s largest internet service providers and telecommunications companies (Core Data Team)"
        skills={["Python", "Scapy", "Networking", "Backend", "DNS"]}
      />
      <ExperienceBox
        date="Jan 2021 — Apr 2021"
        location="Remote"
        position="Research Assistant"
        company="Google exploreCSR"
        description="Conducted natural language processing research under Dr. Jonathan K. Kummerfeld and developed a NLP model that analyzes politicians' tweets and classifies them according to specific labels/topics."
        skills={["Scikit Learn", "Python", "NLP"]}
        link={["Dr. Jonathan K. Kummerfeld", "https://jkk.name/"]}
      />
      <ExperienceBox
        date="Sep 2020 — Dec 2021"
        location="Remote"
        position="Full Stack Engineer"
        company="BridgeBurma"
        description="Collaborated with a team of eight developers to develop and deploy a professional networking and mentorship platform that connects Burmese students from around the world."
        skills={["AWS S3", "React.js", "Node.js", "MongoDB", "MERN Stack"]}
        link={[
          "professional networking and mentorship platform",
          "https://www.bridgeburma.com/",
        ]}
      />
      <div className={styles.resumeContainer}>
        <a href={pdf} target="_blank" rel="noreferrer">
          View Full Resume →
        </a>
      </div>
    </div>
  );
});

export default Experiences;
